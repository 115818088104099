<div class="main-content body-bg p-6 project-detail">
  <p class="d-flex align-center textXS fw-400 pb-4">
    <mat-icon style="cursor: pointer" class="pr-2" (click)="goBack()"
      >arrow_back</mat-icon
    >Back to List / Project Details
  </p>
  <div class="d-flex">
    <div class="accounts-detail sidebar-width mr-4">
      <div>
        <div class="p-6 d-coloumn h-m">
          <div>
            <div class="d-flex justify-between mb-6">
              <div class="">
                <span class="mat-caption text fw-600 d-block mb-2"  style="margin-right: 12px; width: 171px; word-wrap: break-word;">{{
                  projectList.name
                }}</span>
              </div>
              <div class="">
                <sft-button
                  [style.cursor]="
                    !canManageResources ? 'not-allowed' : 'pointer'
                  "
                  [label]="'Edit'"
                  [color]="'primary'"
                  [variant]="'stroked'"
                  [disable]="false"
                  [icon]="'home'"
                  [disable]="!canManageResources"
                  [matTooltip]="
                    canManageResources
                      ? ''
                      : 'You do not have permission to add project'
                  "
                  [size]="'medium'"
                  (buttonClick)="editProjectDialog()"
                ></sft-button>
              </div>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2"
                >Project Account</span
              >
              <span
                class="textS text grey-text"
                [title]="projectAccount || '--'"
              >
                {{ projectAccount | truncate : 10 || "--" }}'s</span
              >
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2">Cost Center</span>
              <span class="textS text grey-text" [title]="centerName || '--'">{{
                centerName | truncate : 10 || "--"
              }}</span>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2"
                >Project Cost Model</span
              >
              <span class="textS text grey-text">{{ costModal }}</span>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2"
                >Project Budget
                {{ currency ? " (" + currency + ")" : "" }}</span
              >
              <span class="textS text grey-text">{{ fixedCost }}</span>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2">Start Date</span>
              <span class="textS text grey-text">{{
                startDate ? (startDate | date : "shortDate") : "--"
              }}</span>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2">End Date</span>
              <span class="textS text grey-text">{{
                endDate ? (endDate | date : "shortDate") : "--"
              }}</span>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2">Status</span>
              <div
                *ngIf="isStatusOngoing(projectList.status)"
                class="success-text success-light-bg w-fit px-2 table-chip"
              >
                {{ getFormattedStatus(projectList.status) }}
              </div>

              <div
                *ngIf="isStatusOnHold(projectList.status)"
                class="warning-light-bg warning-text w-fit px-2 table-chip"
              >
                {{ getFormattedStatus(projectList.status) }}
              </div>

              <div
                *ngIf="isStatusCompleted(projectList.status)"
                class="info-text info-light-bg w-fit px-2 table-chip"
              >
                {{ getFormattedStatus(projectList.status) }}
              </div>
            </div>
          </div>
          <!-- <div class="text-center pt-6">
            <p class="textXS fw-400 text-grey4">
              last Update: {{ lastUpdated | date : "d MMM y" }}
              {{ lastUpdated | date : "h:mm a" : "UTC" }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="account-detail-tab with-scroll w-full">
      <div>
        <div class="pt-6 px-6 pb-0">
          <mat-tab-group
            animationDuration="0ms"
            [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="onTabChange($event)"
          >
            <mat-tab label="Associated Resource">
              <div class="pb-6 pt-6">
                <div
                  class="d-flex justify-between align-center account-detail-actions"
                >
                  <div class="search-block">
                    <mat-form-field
                      appearance="outline"
                      class="search-input"
                      [formGroup]="form"
                    >
                      <input
                        type="text"
                        matInput
                        placeholder="Search"
                        formControlName="searchQuery"
                      />
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </div>
                  <sft-button
                    [label]="'Add Resources'"
                    [color]="'primary'"
                    [variant]="'flat'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'large'"
                    (buttonClick)="openAddResourceDialog()"
                  ></sft-button>
                </div>
              </div>
              <div class="account-detail-table">
                <app-loader *ngIf="loading"></app-loader>
                <table
                  *ngIf="!loading && projectAccount.length > 0"
                  mat-table
                  [dataSource]="associatedResource"
                  matSort
                  (matSortChange)="onSort($event)"
                  style="cursor: pointer"
                >
                  <ng-container matColumnDef="position">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header="resourceName"
                      class="title-col"
                    >
                      Resource Name
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="grey-text"
                      title="{{ element.resource.name }}"
                      [routerLink]="
                        '/resource/view/' + element.resource.resourceID
                      "
                    >
                      <div class="d-flex align-center">
                        <ng-container *ngIf="element.logo; else defaultLogo">
                          <img
                            src="/images/profile.png"
                            alt="profile"
                            style="border-radius: 50%"
                          />
                        </ng-container>
                        <ng-template #defaultLogo>
                          <div
                            class="table-avatar mr-2"
                            style="
                              background-color: #005F8E;
                              color: #fff;
                              border-radius: 50%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            {{ getDefaultPhotoText(element.resource.name) }}
                          </div>
                        </ng-template>
                        <span class="table-ellipse type-info-col">
                          {{ element.resource.name }}
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header="role"
                      class="title-col"
                    >
                      Role
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="grey-text table-ellipse type-info-col"
                      title=" {{ element.resource.role }}"
                      [routerLink]="
                        '/resource/view/' + element.resource.resourceID
                      "
                    >
                      {{ element.resource.role }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="weight">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header="salary"
                      class="title-col"
                    >
                      Resource Salary
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="grey-text"
                      [routerLink]="
                        '/resource/view/' + element.resource.resourceID
                      "
                    >
                      {{
                        element.resource.salary !== null &&
                        isValidNumber(element.resource.salary)
                          ? (element.resource.salary | number : "1.2-2")
                          : "--"
                      }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      mat-sort-header="modifiedAt"
                      class="title-col"
                    >
                      Last Updated
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="grey-text"
                      [routerLink]="
                        '/resource/view/' + element.resource.resourceID
                      "
                    >
                      {{
                        element?.modifiedAt
                          ? (element?.modifiedAt
                            | date : "d MMM y h:mm a" : "UTC")
                          : "--"
                      }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    class="account-detail-header"
                    *matHeaderRowDef="displayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    class="account-detail-body"
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
                <div
                  *ngIf="associatedResource.length === 0"
                  class="mt-6 no-resources d-flex align-center justify-center"
                >
                  <p class="required-star" style="font-size: 1.5rem">
                    No associated resources found.
                  </p>
                </div>
                <mat-paginator
                  *ngIf="associatedResource.length > 0"
                  class="action-table-pagination expense activity"
                  [length]="totalResourceRecords"
                  [pageSize]="resourcePageSize"
                  [pageIndex]="resourcePageNumber"
                  [pageSizeOptions]="[25, 50, 75, 100]"
                  (page)="onResourcePageChange($event)"
                  aria-label="Select page"
                >
                </mat-paginator>
              </div>
            </mat-tab>
            <mat-tab label="Activity">
              <div class="search-block mt-6">
                <mat-form-field
                  appearance="outline"
                  class="search-input"
                  [formGroup]="activityForm"
                >
                  <input
                    type="text"
                    matInput
                    placeholder="Search"
                    (keydown.enter)="$event.preventDefault()"
                    formControlName="searchQuery"
                  />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>
              <div class="activity-listing mt-6">
                <app-loader *ngIf="loading"></app-loader>
                <ng-container
                  *ngIf="activityLogsGrouped.length > 0; else noLogsMessage"
                >
                  <ng-container *ngFor="let group of activityLogsGrouped">
                    <div class="py-2">
                      <p class="body-bg p-2 fw-400 textXS">
                        {{ formatDate(group.date) }}
                      </p>
                      <div
                        *ngFor="let log of group.logs"
                        class="activity-update d-flex align-center"
                      >
                        <div class="activity-time">
                          <p class="p-2 text-grey4 fw-400 textXS">
                            {{ log.timestamp | date : "shortTime" : "UTC" }}
                          </p>
                        </div>
                        <div class="activity-info align-center d-flex mr-8">
                          <div class="avatar-smX mr-2">
                            <div
                              style="
                                background-color: #005F8E;
                                color: #fff;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 10px;
                              "
                            >
                              {{ getDefaultPhotoText(log.user.name) }}
                            </div>
                          </div>
                          <span class="textS text">{{ log.user.name }}</span>
                        </div>
                        <div class="activity-discription">
                          <p class="p-2 text-grey4 fw-400 textXS">
                            {{ log.activityDetail }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noLogsMessage>
                  <p
                    class="required-star"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                      font-size: 16px;
                    "
                  >
                    No activity logs found.
                  </p>
                </ng-template>
              </div>
              <mat-paginator
                *ngIf="activityLogsGrouped.length > 0"
                class="action-table-pagination expense activity"
                [length]="totalActivityRecords"
                [pageSize]="activityPageSize"
                [pageIndex]="activityPageNumber"
                [pageSizeOptions]="[25, 50, 75, 100]"
                (page)="onActivityPageChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
