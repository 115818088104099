import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { SftInputModule } from 'src/stories/components/Input/input.module';
import { AccountComponent } from 'src/stories/components/account-list/account.component';
import { ActionTableComponent } from 'src/stories/components/action-table/action-table.component';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { SftSwitchButtonModule } from 'src/stories/components/switch/switch.module';
import { ProjectListingComponent } from './components/project-listing/project-listing.component';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { RouterModule } from '@angular/router';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { FilterProjectSidebarComponent } from './components/project-listing/filter-sidebar/filter-project-sidebar.component';

@NgModule({
  declarations: [
    ProjectListingComponent,
    ProjectDetailComponent,
    FilterProjectSidebarComponent,
  ],
  imports: [
    LoaderComponent,
    FormsModule,
    AngularMaterialModule,
    SidebarModule,
    AccountComponent,
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    ActionTableComponent,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    ProjectRoutingModule,
  ],
})
export class ProjectModule {}
