import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { ProjectDialogComponent } from 'src/shared/components/dialog/admin-dialogs/project-dialog/project-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, elementAt, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { Sort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PageEvent } from '@angular/material/paginator';
import { ProjectResourceDialogAccountDetailsComponent } from 'src/shared/components/dialog/admin-dialogs/project-resource-dialog-account-detail/project-resource-dialog-account-detail.component';

interface Project {
  modifiedAt: string;
  name: string;
  costModal: string;
  status: string;
  budget: number;
  startDate: string;
  endDate: string | null;
  costCenter: {
    centerName: string;
    account: {
      name: string;
    };
  };
}

interface ActivityLog {
  timestamp: string;
  user: {
    name: string;
  };
  activityDetail: string;
}

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
})
export class ProjectDetailComponent {
  id!: string;
  accountId!: string;
  projectAccount!: string;
  centerName!: string;
  costModal!: string;
  fixedCost!: string;
  lastUpdated!: string;
  startDate!: string;
  endDate!: string;
  projectList: Project = {} as Project;
  isSidebarOpen = false;
  isDrawerOpen = false;
  associatedResource: any[] = [];
  currency!: string;
  resource: any[] = [];
  resourcePageNumber: number = 0;
  activityPageNumber: number = 0;
  resourcePageSize: number = 25;
  activityPageSize: number = 25;
  search: string = '';
  sortBy: 'resourceName' | 'modifiedAt' = 'modifiedAt';
  lastSortBy: string = '';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  drawerWidth = 200;
  isActive = false;
  form!: FormGroup;
  activityForm!: FormGroup;
  loading: boolean = false;
  displayedColumns: string[] = ['position', 'name', 'weight', 'date'];
  dataSource = new MatTableDataSource<Project>([]);
  activityLogsGrouped: { date: string; logs: ActivityLog[] }[] = [];
  selectedTabIndex: number = 0;
  selectTab: string = 'Associated Resource';
  totalResourceRecords: number = 0;
  totalActivityRecords: number = 0;
  canManageResources: boolean = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  @Output() resourceAdded: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const resourcePermissions = userPermissions.find(
        (p: any) => p.module === 'project'
      );
      if (
        resourcePermissions &&
        resourcePermissions.permission.includes('view') &&
        resourcePermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.id = this.route.snapshot.params['id'];
    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.activityForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchResourceList();
    this.searchActivityLogs();
    this.fetchProjectDetails();
    this.associatedResourceList(
      this.resourcePageNumber,
      this.resourcePageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
    this.selectedTabIndex = 0;
    this.onTabChange({ index: 0 } as MatTabChangeEvent);
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    switch (this.selectedTabIndex) {
      case 0:
        this.selectTab = 'Associated Resource';
        break;
      case 1:
        this.selectTab = 'Activity';
        this.fetchActivityLogs();
        break;
    }
  }

  onResourcePageChange(event: PageEvent): void {
    this.resourcePageNumber = event.pageIndex;
    this.resourcePageSize = event.pageSize;
    this.associatedResourceList(
      this.resourcePageNumber,
      this.resourcePageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  openAddResourceDialog(): void {
    const dialogRef = this.dialog.open(
      ProjectResourceDialogAccountDetailsComponent,
      {
        height: 'auto',
        width: '467px',
        data: {
          isEditMode: false,
          element: this.id,
          costModal: this.costModal,
          projectId: this.id,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.associatedResourceList(
          this.resourcePageNumber,
          this.resourcePageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
      this.resourceAdded.emit();
    });
  }

  onActivityPageChange(event: PageEvent): void {
    this.activityPageNumber = event.pageIndex;
    this.activityPageSize = event.pageSize;
    this.fetchActivityLogs();
  }

  isStatusOngoing(status: string): boolean {
    return (
      !!status &&
      (status.toLowerCase() === 'active' || status.toLowerCase() === 'ongoing')
    );
  }

  isStatusOnHold(status: string): boolean {
    return !!status && status.toLowerCase() === 'hold';
  }

  isStatusCompleted(status: string): boolean {
    return !!status && status.toLowerCase() === 'completed';
  }

  getFormattedStatus(status: string): string {
    if (!status) {
      return '';
    }

    switch (status.toLowerCase()) {
      case 'active':
        return 'Ongoing';
      case 'hold':
        return 'On hold';
      case 'completed':
        return 'Completed';
      default:
        return '';
    }
  }

  fetchProjectDetails(): void {
    this.projectService.getProjectById(this.id).subscribe((res) => {
      this.projectList = res.data;
      const projectList = res.data;
      this.projectAccount = projectList?.account?.name;
      this.centerName = projectList?.costCenter?.centerName;
      this.costModal = projectList?.costModal;
      this.fixedCost = projectList?.fixedCost;
      this.startDate = projectList?.startDate;
      this.endDate = projectList?.endDate;
      this.lastUpdated = projectList.modifiedAt;
      if (res.data.costCenter && res.data.costCenter.account) {
        this.accountId = res.data.costCenter.account.accountID;
      }
    });
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.resourcePageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.projectService
      .getProjectResource(
        this.resourcePageNumber,
        this.resourcePageSize,
        this.search,
        sortBy,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.associatedResource = data?.data?.records || [];
          this.dataSource.data = this.associatedResource;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Resource List request:', error);
        },
      });
  }

  associatedResourceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    projectId: string
  ): void {
    this.loading = true;
    this.projectService
      .getProjectResource(pageNumber, limit, search, sortBy, orderBy, projectId)
      .subscribe({
        next: (data: any) => {
          this.associatedResource = data.data.records;
          this.totalResourceRecords = data?.data?.count || 0;
          this.currency =
            data.data.records[0]?.project?.costCenter?.account?.financials[0]?.currency;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error fetching total resource count:', error);
          this.loading = false;
        },
      });
  }

  searchResourceList(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.associatedResourceList(
          this.resourcePageNumber,
          this.resourcePageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  fetchActivityLogs(): void {
    this.loading = true;
    this.projectService
      .getActivityLogs(
        this.activityPageNumber,
        this.activityPageSize,
        this.search,
        'timestamp',
        'DESC'
      )
      .subscribe((res) => {
        const logs: ActivityLog[] = res.data.records;
        this.totalActivityRecords = res?.data?.count || 0;
        const groupedLogs: { [date: string]: ActivityLog[] } = {};
        logs.forEach((log: ActivityLog) => {
          const date = new Date(log.timestamp).toDateString();
          if (!groupedLogs[date]) {
            groupedLogs[date] = [];
          }
          groupedLogs[date].push(log);
        });
        this.activityLogsGrouped = Object.entries(groupedLogs).map(
          ([date, logs]) => ({
            date,
            logs,
          })
        );
        this.loading = false;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (this.isToday(date)) {
      return `Todays - ${this.formatDateToString(date)}`;
    } else if (this.isYesterday(date)) {
      return `Yesterday - ${this.formatDateToString(date)}`;
    } else {
      return this.formatDateToString(date);
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  private formatDateToString(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  }

  getDefaultPhotoText(name: string): string {
    if (!name) return '';

    const names = name.trim().split(/\s+/);
    let firstChar = '';
    let secondChar = '';

    if (names.length > 0) {
      firstChar = names[0].charAt(0);
      if (names.length > 1) {
        secondChar = names[1].charAt(0);
      }
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  searchActivityLogs(): void {
    this.loading = true;
    this.activityForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.fetchActivityLogs();
      });
  }

  editProjectDialog(): void {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      data: {
        isEditMode: true,
        element: this.projectList,
        accountID: this.accountId,
      },
      height: 'auto',
      width: '520px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.fetchProjectDetails();
      }
    });
  }

  isValidNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  goBack() {
    this.router.navigateByUrl('/projects');
  }
}
