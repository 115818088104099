import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../services/project.service';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectDialogComponent } from 'src/shared/components/dialog/admin-dialogs/project-dialog/project-dialog';
import { FilterProjectSidebarComponent } from './filter-sidebar/filter-project-sidebar.component';

export interface PeriodicElement {
  name: string;
  account: string;
  costCenter: string;
  model: string;
  startDate: string;
  status: string;
  lastupdated: string;
}

@Component({
  selector: 'app-project-module',
  templateUrl: './project-listing.component.html',
})
export class ProjectListingComponent implements OnInit {
  project: any[] = [];
  accounts: any[] = [];
  loading: boolean = false;

  displayedColumns: string[] = [
    'name',
    'account',
    'costCenter',
    'model',
    'startDate',
    'status',
    'lastupdated',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>([]);
  isSidebarOpen = false;
  isSidepanelOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'projectName' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  form!: FormGroup;
  element: any;
  costModal: string | undefined = undefined;
  lastSortBy: string = '';
  canManageResources: boolean = false;
  totalRecords: number = 0;
  isFilterApplied: boolean = false;

  constructor(
    public dialog: MatDialog,
    private projectService: ProjectService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('filter') filterComponent!: FilterProjectSidebarComponent;

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const resourcePermissions = userPermissions.find(
        (p: any) => p.module === 'project'
      );
      if (
        resourcePermissions &&
        resourcePermissions.permission.includes('view') &&
        resourcePermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.sortBy = 'projectName';
    this.orderBy = 'DESC';
    this.getProjectList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy,
      this.costModal
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
      account: [''],
    });
    this.searchProject();
  }

  getProjectList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    costModal: string | undefined
  ): void {
    this.loading = true;
    this.projectService
      .getProjectList(pageNumber, limit, search, sortBy, orderBy, costModal)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.project = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.project.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
          this.loading = false;
        },
      });
  }

  getProjectListByCostModal(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostModal: string[]
  ): void {
    this.loading = true;
    const costModal = selectedCostModal.join('&costModal=');
    this.projectService
      .getProjectList(pageNumber, limit, search, sortBy, orderBy, '', costModal)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.project = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.project.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
          this.loading = false;
        },
      });
  }

  getProjectListByStatus(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedStatus: string[] = []
  ): void {
    this.loading = true;
    const statusName = selectedStatus.join('&status=');
    this.projectService
      .getProjectList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        '',
        '',
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.project = data?.data?.records;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching project list:', error);
          this.loading = false;
        },
      });
  }

  getProjectListByAccount(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedAccounts: string[]
  ): void {
    this.loading = true;
    const accountId = selectedAccounts.join('&accountID=');
    this.projectService
      .getProjectList(pageNumber, limit, search, sortBy, orderBy, accountId)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.project = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in  Project List request:', error);
          this.loading = false;
        },
      });
  }

  getProjectListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedAccounts: string[],
    selectedCostModal: string[],
    selectedStatus: string[]
  ): void {
    this.loading = true;
    const accountId = selectedAccounts.join('&accountID=');
    const costModal = selectedCostModal.join('&costModal=');
    const status = selectedStatus.join('&status=');
    this.projectService
      .getProjectList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        accountId,
        costModal,
        status
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.project = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Project List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any): void {
    const selectedAccounts = filters.selectedAccounts || [];
    const selectedCostModal = filters.selectedCostModal || [];
    const selectedStatus = filters.selectedStatus || [];
    if (selectedAccounts.length > 0 && selectedCostModal.length > 0) {
      this.getProjectListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedAccounts,
        selectedCostModal,
        selectedStatus
      );
    } else if (selectedCostModal.length > 0 && selectedStatus.length > 0) {
      this.getProjectListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedAccounts,
        selectedCostModal,
        selectedStatus
      );
    } else if (selectedAccounts.length > 0 && selectedStatus.length > 0) {
      this.getProjectListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedAccounts,
        selectedCostModal,
        selectedStatus
      );
    } else if (selectedAccounts.length > 0) {
      this.getProjectListByAccount(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedAccounts
      );
    } else if (selectedCostModal.length > 0) {
      this.getProjectListByCostModal(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedCostModal
      );
    } else if (selectedStatus.length > 0) {
      this.getProjectListByStatus(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedStatus
      );
    } else {
      this.getProjectList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.costModal
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.projectService
      .getProjectList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.costModal
      )
      .subscribe({
        next: (data: any) => {
          this.project = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.dataSource.data = this.project;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
        },
      });
  }

  isStatusOngoing(status: string): boolean {
    return (
      !!status &&
      (status.toLowerCase() === 'active' || status.toLowerCase() === 'ongoing')
    );
  }

  isStatusOnHold(status: string): boolean {
    return !!status && status.toLowerCase() === 'hold';
  }

  isStatusCompleted(status: string): boolean {
    return !!status && status.toLowerCase() === 'completed';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Ongoing';
      case 'hold':
        return 'On hold';
      case 'completed':
        return 'Completed';
      default:
        return '';
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getProjectList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC',
      this.costModal
    );
  }

  searchProject(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getProjectList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.costModal
        );
      });
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      height: 'auto',
      width: '530px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getProjectList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.costModal
        );
      }
    });
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(ProjectDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, element: element },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        const index = this.project.findIndex((p) => p.id === element.id);
        if (index !== -1) {
          this.project.splice(index, 1);
          this.project.unshift(result.updatedProject);
        }
        this.getProjectList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.costModal
        );
      }
    });
  }
}
