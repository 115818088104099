import { Component, EventEmitter, Output } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Observable, tap } from 'rxjs';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';

@Component({
  selector: 'app-project-dialog-filter',
  templateUrl: './filter-project-sidebar.component.html',
})
export class FilterProjectSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  accountID: string = '';
  items = ['Accounts', 'Cost Model', 'Status'];
  expandedIndex = 0;
  costCenters: any[] = [];
  accounts: any[] = [];
  selectedAccounts: string[] = [];
  selectedCostModal: string[] = [];
  selectedStatus: string[] = [];

  selectedAccountsMap: { [key: string]: boolean } = {};
  selectedCostModalMap: { [key: string]: boolean } = {};
  selectedStatusMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(private accountService: AccountService) {}

  ngOnInit() {
    this.loadAccounts().subscribe();
  }

  loadAccounts(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.accountService
      .getAccountList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.accounts = response.data.records;
        })
      );
  }

  hasSelectedFilters(): boolean {
    return (
      this.selectedCostModal.length > 0 ||
      this.selectedAccounts.length > 0 ||
      this.selectedStatus.length > 0
    );
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedAccounts: this.selectedAccounts,
        selectedCostModal: this.selectedCostModal,
        selectedStatus: this.selectedStatus,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedAccounts = [];
    this.selectedCostModal = [];
    this.selectedStatus = [];
    this.selectedAccountsMap = {};
    this.selectedCostModalMap = {};
    this.selectedStatusMap = {};
    this.applyFilters();
    this.filtersApplied.emit({});
  }

  onAccountChange(event: any, account: any): void {
    if (event.checked) {
      this.selectedAccounts.push(account.accountID);
    } else {
      const index = this.selectedAccounts.indexOf(account.accountID);
      if (index > -1) {
        this.selectedAccounts.splice(index, 1);
      }
    }
  }

  onCostModalChange(checked: boolean, costModal: string): void {
    if (checked && !this.selectedCostModal.includes(costModal)) {
      this.selectedCostModal.push(costModal);
    } else if (!checked && this.selectedCostModal.includes(costModal)) {
      const index = this.selectedCostModal.indexOf(costModal);
      if (index > -1) {
        this.selectedCostModal.splice(index, 1);
      }
    }
  }

  onStatusChange(checked: boolean, status: string): void {
    if (checked && !this.selectedStatus.includes(status)) {
      this.selectedStatus.push(status);
    } else if (!checked && this.selectedStatus.includes(status)) {
      const index = this.selectedStatus.indexOf(status);
      if (index > -1) {
        this.selectedStatus.splice(index, 1);
      }
    }
  }

  toggleAccountCheckbox(event: any, project: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleCostModalCheckbox(event: any, costModal: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleStatusCheckbox(event: any, status: string) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  costModalMapping: { [key: string]: string } = {
    hourly: 'Hourly',
    fixed: 'Fixed',
  };

  statusMapping: { [key: string]: string } = {
    active: 'Ongoing',
    hold: 'Onhold',
    completed: 'Completed',
  };
}
