<div class="main-content body-bg listing-wrapper project-listing">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong" style="margin-bottom: 0">
          Project Management
        </h4>
        <p class="medium mat-body-1 textXS text" style="margin-top: 0">
          Manage Your Project
        </p>
      </div>
      <div class="d-flex align-center">
        <mat-form-field
          [formGroup]="form"
          appearance="outline"
          class="search-input"
        >
          <input
            formControlName="searchQuery"
            type="text"
            matInput
            placeholder="Search"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="d-flex mr-2 justify-between header-info-wrapper">
          <button
            mat-button
            class="outline-button"
            (click)="openFilterDrawer()"
          >
            <img src="/images/filter.png" alt="" class="mr-2" />
            <div class="d-flex align-center">
            <span class="mr-2">Filter</span>
            <div *ngIf="isFilterApplied" class="active-circle"></div>
            </div>
          </button>
        </div>
        <sft-button
          [style.cursor]="!canManageResources ? 'not-allowed' : 'pointer'"
          [label]="'Add Project'"
          [color]="'primary'"
          [variant]="'flat'"
          [disable]="false"
          [icon]="'home'"
          [disable]="!canManageResources"
          [matTooltip]="
            canManageResources
              ? ''
              : 'You do not have permission to add project'
          "
          [size]="'xl'"
          (buttonClick)="openAddDialog()"
        ></sft-button>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div
      *ngIf="!loading && project.length === 0"
      class="required-star"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        font-size: 20px;
      "
    >
      No projects found!
    </div>
    <div
      class="body-bg listing-content overflow-scroll Project-listing-wrappper"
      *ngIf="!loading && project.length > 0"
    >
      <table
        mat-table
        [dataSource]="project"
        matSort
        class="custom-action-table"
        style="cursor: pointer"
        (matSortChange)="onSort($event)"
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="projectName"
            class="name"
          >
            Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
            title="{{ element.name || '--' }}"
          >
            <div class="d-flex align-center">
              <span class="project-name-col table-ellipse">
                {{ element.name | truncate : 10 || "--" }}</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="account">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="accountName"
            class="name"
          >
            Account
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
            class="project-name-col table-ellipse"
            title="{{ element?.accountName || '--' }}"
          >
            {{ element?.accountName || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="costCenter">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="centerName"
            class="cost-name"
          >
            Cost Center
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
            title="{{
              (element?.center_name || '--') + ' | ' + (element?.code || '--')
            }}"
          >
            <div class="d-flex align-center">
              <span class="project-name-col table-ellipse">
                {{ (element?.center_name | truncate : 10) || "--" }} | {{
                  (element?.code | truncate : 10) || "--"
                }}</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="model">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="costModal"
            class="modal"
          >
            Project Cost Model
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
          >
            {{
              element.cost_modal === "hourly"
                ? "Hourly"
                : element.cost_modal === "fixed"
                ? "Fixed"
                : element.cost_modal === "daily"
                ? "Daily"
                : "--"
            }}
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="budget">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="budget">
            Project Budget
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
          >
            {{
              element.cost_modal === "hourly"
                ? "NA"
                : element.budget
                ? (element.budget | number : "1.2-2")
                : "NA"
            }}
          </td>
        </ng-container> -->

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="date">
            Start Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
          >
            {{ element.start_date | date : "dd/MM/yy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="status">
            Status
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
          >
            <div
              *ngIf="isStatusOngoing(element.status)"
              class="success-text success-light-bg w-fit px-2 table-chip"
            >
              {{ getFormattedStatus(element.status) }}
            </div>

            <div
              *ngIf="isStatusOnHold(element.status)"
              class="warning-light-bg warning-text w-fit px-2 table-chip"
            >
              {{ getFormattedStatus(element.status) }}
            </div>

            <div
              *ngIf="isStatusCompleted(element.status)"
              class="info-text info-light-bg w-fit px-2 table-chip"
            >
              {{ getFormattedStatus(element.status) }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastupdated">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="modifiedAt"
            sortActionDescription="Sort by date"
            class="updated"
          >
            Last Updated
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/projects/view/' + element.projectId"
          >
            {{
              element.modified_at
                ? (element.modified_at | date : "d MMM y h:mm a" : "UTC")
                : "--"
            }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="action-table-header-data"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          class="action-table-body-data"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        *ngIf="project.length > 0"
        class="action-table-pagination fixed bottom-0 project-listing"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<app-project-dialog-filter
  #filter
  (filtersApplied)="handleFilterApplied($event)"
></app-project-dialog-filter>
